

.header-container{
    background-color: greenyellow;
    width: 100%;
    height: 3rem;
    padding-top: 0,5cm;
}

.header-container>h1{
        color: #000000;
        text-align: center;


} 


.menuNav{
    height: 2.4rem;
    background-color: #FF0075;
    transition: .4s ease-in-out;
    }
    
    .notVisible{
    display: none;
    transition: .4s ease-in-out;
    }
    
.menuNav>ul{
    margin-top: 0;
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    padding: 0;
    }
    
    .fa-home, .fa-bars, .round{
    color: #fff;
    text-decoration: none;
    
    }
    
    .round{
    font-weight: bolder;
    }
    
    .menuNav>ul>li{
    margin-top: 0.5rem;
    }
    
    .menuNav>ul>li>span{
    color: #3E065F;
    font-weight: bolder;
    margin-left: 0.3rem;
    }
    
    .onlySpan{
    margin-right: 0.3rem;
    } 