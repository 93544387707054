.repocard {
  width: 80%;
  border: 2px solid black;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #4e9f3d;
  border-radius: 10px;
  color: #fff;
}
.repocard > img {
  width: 20%;

  margin: 0 auto;
}
.repocard > h4 {
  text-transform: capitalize;
  margin-left: 10px;
}
.repocard > a {
  color: black;
  text-decoration: none;
  text-align: center;
  border: 1px solid white;
  width: 70%;
  margin: 0 auto;
  padding: 5px 0;
  background-color: black;
  color: white;
}
