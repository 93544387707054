.form-container{
    display: flex;
    justify-content: center;
    margin-top: 5rem;
}

.form.form-container input[type=text] { 
    padding: 10px; font-size: 17px; border: 1px solid grey; float: left; width: 80%; background: #f1f1f1; } 

.form.form-container button { 
    float: left; width: 20%; padding: 10px; background: #2196F3; color: white; font-size: 17px; border: 1px solid grey; border-left: none; /* Prevent double borders */ cursor: pointer; } 

.form.form-container button:hover { 
    background: #0b7dda; } 